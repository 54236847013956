export const games = [
  {
    name: 'makoren_conjurer',
    config: {
      config_version: 1,
      game_name: 'Conjurer',
      authors: 'Makoren',
      prompt: 'MATCH',
      init_room: 'makoren_conjurer_rm_main',
      view_width: 120,
      view_height: 80,
      time_seconds: 8,
      music_track: 'sng_ennway_bit_battle',
      music_loops: true,
      interpolation_on: false,
      cartridge_col_primary: [153, 102, 255],
      cartridge_col_secondary: [69, 41, 95],
      cartridge_label: 'makoren_conjurer_label',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      credits: ['Makoren'],
      date_added: '21/07/10',
      is_enabled: true,
      supports_html: true,
      supports_pi: true,
      show_on_website: true,
    },
    authorIds: ['makoren'],
  },
  {
    name: 'objfrog_parking_plunder',
    config: {
      config_version: 1,
      game_name: 'Parking Plunder',
      authors: 'objFrog',
      prompt: 'PARK',
      init_room: 'objfrog_pp_r_init',
      view_width: 240,
      view_height: 160,
      time_seconds: 12,
      music_track: 'sng_ennway_bit_battle',
      music_loops: true,
      interpolation_on: false,
      cartridge_col_primary: [150, 150, 30],
      cartridge_col_secondary: [215, 215, 30],
      cartridge_label: 'objfrog_pp_s_label',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      credits: ['objFrog', 'ennway'],
      date_added: {
        day: 23,
        month: 11,
        year: 2021,
      },
      is_enabled: true,
      supports_html: true,
      supports_pi: true,
      show_on_website: true,
      description: [
        "Park your car in the marked spot. That's all there is to it, stinker.",
      ],
      how_to_play: ['Left/right to turn, A to accelerate, B to reverse.'],
    },
    authorIds: ['objfrog'],
  },
  {
    name: 'objfrog_yeehaw_shooter',
    config: {
      config_version: 1,
      game_name: 'Yeehaw Shooter',
      authors: 'objFrog',
      prompt: "SPIN 'N SHOOT",
      init_room: 'objfrog_ys_room',
      view_width: 240,
      view_height: 160,
      time_seconds: 10,
      music_track: 'objfrog_ys_music',
      music_loops: true,
      interpolation_on: false,
      cartridge_col_primary: [110, 80, 35],
      cartridge_col_secondary: [165, 115, 45],
      cartridge_label: 'objfrog_ys_s_label',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      credits: ['objFrog', '@wise1studio', 'space', 'Zandy'],
      date_added: {
        day: 3,
        month: 11,
        year: 2021,
      },
      is_enabled: true,
      supports_html: true,
      supports_pi: true,
      show_on_website: true,
      description: [
        "Practice yer' aim in the wild west by shootin' some targets. Hit 'em all within the time limit.",
      ],
      how_to_play: ['Left and right to spin, A to shoot.'],
    },
    authorIds: ['objfrog'],
  },
  {
    name: 'sandveech_bergur',
    config: {
      config_version: 1,
      game_name: 'Bergur',
      authors: 'Nompang Studios',
      prompt: 'TOSS INGREDIENTS ONTO PLATE!',
      init_room: 'sandveech_bg_rm_main',
      view_width: -1,
      view_height: -1,
      time_seconds: 12,
      music_track: 'sandveech_bg_mus_borgor',
      music_loops: 'noone',
      interpolation_on: false,
      cartridge_col_primary: [213, 141, 28],
      cartridge_col_secondary: [102, 57, 49],
      cartridge_label: 'sandveech_bg_spr_label',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      credits: [
        'Sandveech',
        'Jet',
        'Hibari',
        'Elle',
        'Dar',
        'Akio',
        'Kio',
        'Ari',
        'Ishigami',
        'Ren',
        'Whippo0r',
        'Minty',
        'sfxr',
      ],
      date_added: {
        day: 10,
        month: 8,
        year: 2024,
      },
      is_enabled: true,
      supports_html: true,
      allow_subpixels: true,
      supports_pi: true,
      show_on_website: true,
      description: ['Make a bergur for yummy in your tummy.'],
      how_to_play: ['WASD to move the arm. Press B to grab. Release B to toss'],
    },
    authorIds: ['sandveech'],
  },
  {
    name: 'n8fl_penguin_blast',
    config: {
      config_version: 1,
      game_version: 1,
      game_version_date: {
        day: 21,
        month: 'July',
        year: 2021,
      },
      date_added: '21/07/05',
      game_name: 'Penguin Blast',
      authors: {
        n8fl: 'net8floz',
      },
      prompt: 'HOLD DOWN, HOLD A/B',
      init_room: 'n8fl_penguin_blast_rm',
      view_width: 240,
      view_height: 160,
      time_seconds: 10,
      music_track: 'sng_zandy_woodblocks',
      music_loops: true,
      interpolation_on: false,
      cartridge_col_primary: [98, 98, 118],
      cartridge_col_secondary: [28, 22, 51],
      cartridge_label: 'n8fl_penguin_blast_label',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      credits: ['net8floz'],
      is_enabled: true,
      description: [
        "The evil orange cat with sunglasses has kidnapped the fair Mimpy Penguin. The only problem? This penguin can't swim.",
      ],
      how_to_play: [
        'Use the Primary Key & Secondary key to match the Red and Green projectiles. Use the Down key to block bombs. Match all the projectiles and save the princess.',
      ],
      author_id: 'n8fl',
      supports_html: true,
      supports_pi: true,
      show_on_website: true,
    },
    authorIds: ['n8fl'],
  },
  {
    name: 'n8fl_reach_for_it_mister',
    config: {
      config_version: 1,
      game_version: 1,
      game_version_date: {
        day: 21,
        month: 'July',
        year: 2021,
      },
      date_added: '21/07/05',
      game_name: 'Reach For It, Mister',
      authors: {
        n8fl: 'net8floz',
      },
      description: [
        'The rad crew are at it again. But this time with cowboy hats.',
      ],
      how_to_play: [
        'Match the buttons on screen before your opponent does! Last hat standing.',
      ],
      prompt: 'Wait for it',
      init_room: 'n8fl_reach_for_it_mister_rm',
      view_width: 240,
      view_height: 160,
      time_seconds: 8,
      music_track: false,
      music_loops: true,
      interpolation_on: false,
      cartridge_col_primary: [89, 62, 71],
      cartridge_col_secondary: [122, 88, 89],
      cartridge_label: 'n8fl_reach_for_it_mister_label',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      credits: ['nt8floz - game', 'meseta - music', 'space - art'],
      supports_html: true,
      supports_pi: true,
      show_on_website: true,
    },
    authorIds: ['n8fl'],
  },
  {
    name: 'katsaii_witchcraft',
    config: {
      config_version: 1,
      game_name: 'Witchcraft',
      authors: {
        katsaii: 'Katsaii',
        mashmerlow: 'Mashmerlow',
      },
      prompt: 'CRAFT',
      init_room: 'katsaii_witchcraft_rm',
      view_width: 1,
      view_height: 1,
      time_seconds: 8,
      music_track: 'katsaii_witchcraft_mu',
      music_loops: false,
      interpolation_on: true,
      cartridge_col_primary: [205, 184, 166],
      cartridge_col_secondary: [214, 105, 98],
      cartridge_label: 'katsaii_witchcraft_label',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      credits: ['Katsaii', 'Mashmerlow'],
      date_added: {
        day: 9,
        month: 'July',
        year: 2021,
      },
      is_enabled: true,
      supports_html: true,
      supports_pi: true,
      show_on_website: true,
      description: [
        "Okay, so basically you're a witch and you craft wands. Thats why the game is called Witchcraft. Okay submit that. No. Stop writing down what I'm saying. Just use the first bit. -- SPEECH-TO-TEXT --",
      ],
      how_to_play: [
        'Use the primary keys to select the highlighted items in order. Missing an item results in a game-over, so be careful. The arrow keys can be used to change the direction of the hand in order to save some time.',
      ],
    },
    authorIds: ['katsaii', 'mashmerlow'],
  },
  {
    name: 'katsaii_witchwanda',
    config: {
      config_version: 1,
      game_name: 'Witch Wanda',
      authors: {
        katsaii: 'Katsaii',
        mashmerlow: 'Mashmerlow',
      },
      prompt: 'SHOOT',
      init_room: 'katsaii_witchwanda_rm_game',
      view_width: -1,
      view_height: -1,
      time_seconds: 12,
      music_track: 'noone',
      music_loops: false,
      interpolation_on: false,
      cartridge_col_primary: [204, 107, 102],
      cartridge_col_secondary: [232, 182, 181],
      cartridge_label: 'katsaii_witchwanda_label',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      allow_subpixels: false,
      credits: ['Katsaii', 'Mashmerlow'],
      date_added: {
        day: 25,
        month: 'August',
        year: 2021,
      },
      is_enabled: true,
      supports_html: true,
      supports_pi: true,
      show_on_website: true,
      description: [
        'You play as Wanda, a friendly witch flying through the sky amongst clouds and floating islands. Suddenly, a group of witches start to attack Wanda and her island. Defeat the wave of witches and protect the island!',
      ],
      how_to_play: [
        'Use the primary keys to fire bullets at opposing witches. Holding down continuous fire will decrease the rate of fire over time, so try to fire in bursts. Use the arrow keys to move around and avoid the projectiles.',
      ],
    },
    authorIds: ['katsaii', 'mashmerlow'],
  },
  {
    name: 'katsaii_wandaxplore',
    config: {
      config_version: 1,
      game_name: 'Wandaxplore',
      authors: {
        katsaii: 'Katsaii',
      },
      prompt: 'EXPLORE',
      init_room: 'katsaii_wandaxplore_rm',
      view_width: -1,
      view_height: -1,
      time_seconds: 12,
      music_track: 'katsaii_wandaxplore_bgm',
      music_loops: false,
      interpolation_on: false,
      cartridge_col_primary: [100, 133, 153],
      cartridge_col_secondary: [119, 158, 181],
      cartridge_label: 'katsaii_wandaxplore_label',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      allow_subpixels: false,
      credits: ['Katsaii', 'Mimpy', 'net8floz'],
      date_added: {
        day: 11,
        month: 'October',
        year: 2021,
      },
      is_enabled: true,
      supports_html: true,
      supports_pi: false,
      show_on_website: true,
      description: ['Solve platforming puzzles to find your broom!'],
      how_to_play: [
        'Use the arrow keys to navigate and the button keys to jump over obstacles.',
      ],
    },
    authorIds: ['katsaii'],
  },
  {
    name: 'katsaii_wandaring',
    config: {
      config_version: 1,
      game_name: 'Wandaring Candyland',
      authors: {
        katsaii: 'Katsaii',
        mashmerlow: 'Mashmerlow',
      },
      prompt: 'COLLECT STARS',
      init_room: 'katsaii_wandaring_rm_game',
      view_width: -1,
      view_height: -1,
      time_seconds: 12,
      music_track: 'noone',
      music_loops: false,
      interpolation_on: false,
      cartridge_col_primary: [85, 67, 122],
      cartridge_col_secondary: [117, 100, 153],
      cartridge_label: 'katsaii_wandaring_label',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      allow_subpixels: false,
      credits: ['Katsaii', 'Mashmerlow'],
      date_added: {
        day: 20,
        month: 'October',
        year: 2021,
      },
      is_enabled: false,
      supports_html: false,
      supports_pi: false,
      show_on_website: true,
      description: ['Collect all the stars and escape Candyland!'],
      how_to_play: [
        'Use the arrow keys to navigate and the button keys to change perspective.',
      ],
    },
    authorIds: ['katsaii', 'mashmerlow'],
  },
  {
    name: 'katsaii_wandawhop',
    config: {
      config_version: 1,
      game_name: 'Wanda Whop',
      authors: {
        katsaii: 'Katsaii',
        mashmerlow: 'Mashmerlow',
      },
      prompt: 'RETURN',
      init_room: 'katsaii_wandawhop_rm',
      view_width: -1,
      view_height: -1,
      time_seconds: 6,
      music_track: 'noone',
      music_loops: false,
      interpolation_on: false,
      cartridge_col_primary: [159, 159, 159],
      cartridge_col_secondary: [236, 236, 236],
      cartridge_label: 'katsaii_wandawhop_label',
      default_is_fail: false,
      supports_difficulty_scaling: true,
      allow_subpixels: false,
      credits: ['Katsaii', 'Mashmerlow'],
      date_added: {
        day: 1,
        month: 'October',
        year: 2023,
      },
      is_enabled: true,
      supports_html: true,
      supports_pi: true,
      show_on_website: true,
      description: ['Whack that stupid witch!'],
      how_to_play: [
        "Use the arrow keys to move the tennis racket. Intercept Wanda before it's too late!",
      ],
    },
    authorIds: ['katsaii', 'mashmerlow'],
  },
  {
    name: 'katsaii_witchball',
    config: {
      config_version: 1,
      game_name: 'Witch Ball',
      authors: {
        katsaii: 'Katsaii',
        mashmerlow: 'Mashmerlow',
      },
      prompt: 'SAVE',
      init_room: 'katsaii_witchball_rm',
      view_width: -1,
      view_height: -1,
      time_seconds: 5,
      music_track: 'katsaii_witchball_ambience',
      music_loops: false,
      interpolation_on: false,
      cartridge_col_primary: '#CBF0FF',
      cartridge_col_secondary: '#8EDFFF',
      cartridge_label: 'katsaii_witchball_label',
      default_is_fail: true,
      supports_difficulty_scaling: false,
      allow_subpixels: false,
      credits: ['Katsaii', 'Mashmerlow'],
      date_added: {
        day: 7,
        month: 'January',
        year: 2024,
      },
      is_enabled: true,
      supports_html: true,
      supports_pi: true,
      show_on_website: true,
      description: ['Loungeware beach episode!'],
      how_to_play: ['Use the arrow keys to dive and save the ball.'],
    },
    authorIds: ['katsaii', 'mashmerlow'],
  },
  {
    name: 'jdllama_hammer',
    config: {
      config_version: 1,
      game_name: "THROWIN' THE HAMMER",
      authors: 'J.D. Lowe',
      prompt: 'THROW HAMMER',
      init_room: 'jdllama_hammer_rm',
      view_width: 240,
      view_height: 160,
      time_seconds: 5,
      music_track: 'jdllama_hammer_snd_theme',
      music_loops: false,
      interpolation_on: false,
      cartridge_col_primary: [0, 0, 0],
      cartridge_col_secondary: [131, 147, 202],
      cartridge_label: 'jdllama_hammer_spr_label',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      credits: ['J.D. Lowe', 'Zandy'],
      date_added: '21/08/12',
      is_enabled: true,
      supports_html: true,
      supports_pi: true,
      show_on_website: true,
    },
    authorIds: ['jdllama'],
  },
  {
    name: 'jdllama_target',
    config: {
      config_version: 1,
      game_name: 'TARGET BREAKING YEAH',
      authors: 'J.D. Lowe',
      prompt: 'SHOOT THE TARGETS',
      init_room: 'jdllama_target_rm',
      view_width: 240,
      view_height: 160,
      time_seconds: 5,
      music_track: 'jdllama_target_snd_theme',
      music_loops: false,
      interpolation_on: false,
      cartridge_col_primary: [0, 0, 0],
      cartridge_col_secondary: [55, 255, 255],
      cartridge_label: 'jdllama_target_spr_label',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      credits: ['J.D. Lowe', 'Zandy'],
      date_added: '21/08/09',
      is_enabled: true,
      supports_html: true,
      supports_pi: true,
      show_on_website: true,
      description: [
        'Invasion! Chaos! ANARCHY! We are being bombarded by living bullseye targets, and only YOU can save us!.',
      ],
      how_to_play: [
        "You have a limited number of shots that can be fired by either the primary or secondary button. If you hit a target, you don't lose a shot. You can also alter which target you're aiming at with either left or right on your controller.",
      ],
    },
    authorIds: ['jdllama'],
  },
  {
    name: 'pixpope_hereticpurge',
    config: {
      config_version: 1,
      game_name: 'Heretic Purge',
      authors: {
        pixpope: 'Pixelated Pope',
      },
      prompt: 'PURGE HERETICS',
      init_room: 'pixpope_hp_rm_main',
      view_width: -1,
      view_height: -1,
      time_seconds: 10,
      music_track: 'pixpope_hp_bgm',
      music_loops: false,
      interpolation_on: false,
      cartridge_col_primary: [116, 164, 60],
      cartridge_col_secondary: [71, 136, 23],
      cartridge_label: 'pixpope_hp_spr_label',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      credits: ['Pixelated Pope'],
      date_added: {
        day: 1,
        month: 8,
        year: 2024,
      },
      is_enabled: true,
      supports_html: true,
      allow_subpixels: true,
      supports_pi: true,
      show_on_website: true,
      description: ['Build up enough holy energy to purge the heretics!'],
      how_to_play: ['Press A and B alternating as quickly as possible!'],
    },
    authorIds: ['pixpope'],
  },
  {
    name: 'pixpope_lockondragoon',
    config: {
      config_version: 1,
      game_name: 'Lock-On Dragoon',
      authors: {
        pixpope: 'Pixelated Pope',
      },
      prompt: 'LOCK ON',
      init_room: 'pixpope_lod_rm_main',
      view_width: -1,
      view_height: -1,
      time_seconds: 10,
      music_track: 'pixpope_lod_bgm_pantser_laroldgoon',
      music_loops: false,
      interpolation_on: false,
      cartridge_col_primary: [116, 164, 60],
      cartridge_col_secondary: [71, 136, 23],
      cartridge_label: 'pixpope_lod_spr_cartridge',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      credits: [
        'Pixelated Pope',
        'Zandy',
        'Jamie Rowan',
        'Pixel Planet Generator',
        'zapsplat.com',
      ],
      date_added: {
        day: 1,
        month: 9,
        year: 2024,
      },
      is_enabled: true,
      supports_html: true,
      allow_subpixels: true,
      supports_pi: true,
      show_on_website: true,
      description: [
        'Lock your weapons on to all the enemies and blow them out of the sky!',
      ],
      how_to_play: [
        'Hold A and use the direction keys to move the target reticle over all the enemies. Release to fire!',
      ],
    },
    authorIds: ['pixpope'],
  },
  {
    name: 'kilomatter_jaywalker',
    config: {
      config_version: 1,
      game_name: 'Jay Walker',
      authors: 'Kilomatter',
      prompt: 'CROSS',
      init_room: 'kilo_jaywalker_rm',
      view_width: 240,
      view_height: 160,
      time_seconds: 6,
      music_track: 'sng_zandy_doctor',
      music_loops: true,
      interpolation_on: false,
      cartridge_col_primary: [164, 70, 89],
      cartridge_col_secondary: [123, 23, 62],
      cartridge_label: 'kilo_jaywalker_sprCartLabel',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      credits: ['Kilomatter', 'Zandy'],
      date_added: '21/08/17',
      is_enabled: true,
      supports_html: true,
      supports_pi: true,
      show_on_website: true,
    },
    authorIds: ['kilomatter'],
  },
  {
    name: 'baku_chug',
    config: {
      config_version: 1,
      game_name: 'Forced Choice of a New Generation',
      authors: 'baku',
      prompt: 'CHUG',
      init_room: 'baku_chug_rm',
      view_width: 160,
      view_height: 107,
      time_seconds: 7,
      music_track: 'sng_zandy_xylo',
      music_loops: false,
      interpolation_on: false,
      cartridge_col_primary: '942C4B',
      cartridge_col_secondary: '4F0B42',
      cartridge_label: 'baku_chug_spr_label',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      credits: ['baku'],
      date_added: {
        day: 8,
        month: 'July',
        year: 2021,
      },
      is_enabled: true,
      supports_html: true,
      supports_pi: true,
      show_on_website: true,
      description: ['Just sid chugging that beverage he likes'],
      how_to_play: ['Press the buttons to chuggit !!'],
    },
    authorIds: ['baku'],
  },
  {
    name: 'baku_mine',
    config: {
      config_version: 1,
      game_name: 'Lonesome Miner',
      authors: 'baku',
      prompt: [
        'MINE DIAMOND',
        'MINE EMERALD',
        'MINE GOLD',
        'MINE RUBY',
        'MINE IRON',
      ],
      init_room: 'baku_mine_rm',
      view_width: 480,
      view_height: 320,
      time_seconds: 12,
      music_track: 'baku_mine_bgm_minecraft',
      music_loops: false,
      interpolation_on: false,
      cartridge_col_primary: '605158',
      cartridge_col_secondary: '2A2325',
      cartridge_label: 'baku_mine_spr_label',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      credits: ['baku', 'meseta'],
      date_added: {
        day: 21,
        month: 'July',
        year: 2021,
      },
      is_enabled: true,
      supports_html: false,
      supports_pi: false,
      show_on_website: true,
      description: ["Larold's dug too deep..."],
      how_to_play: [
        "Imagine playing an FPS on a gamebouy...you'll figure it out",
      ],
    },
    authorIds: ['baku'],
  },
  {
    name: 'baku_skate',
    config: {
      config_version: 1,
      game_name: 'Kickflipper',
      authors: 'baku',
      prompt: "DON'T CRASH",
      init_room: 'baku_skate_rm',
      view_width: 480,
      view_height: 320,
      time_seconds: 12,
      music_track: 'sng_zandy_epic_sk8r',
      music_loops: true,
      interpolation_on: false,
      cartridge_col_primary: '000000',
      cartridge_col_secondary: 'ffffff',
      cartridge_label: 'baku_skate_spr_label',
      default_is_fail: false,
      supports_difficulty_scaling: true,
      credits: ['baku', 'mimpy', 'net8floz', 'zapsplat.com'],
      date_added: {
        day: 17,
        month: 'October',
        year: 2021,
      },
      is_enabled: true,
      supports_html: true,
      supports_pi: true,
      show_on_website: true,
      description: [
        "Normal penguins have flippers. This one's got kickflippers.",
      ],
      how_to_play: [
        'Do a well-timed kickflip to avoid crashing into the obstacle!',
      ],
    },
    authorIds: ['baku'],
  },
  {
    name: 'giz_laronin',
    config: {
      config_version: 1,
      game_name: 'Laronin',
      authors: 'Gizmo199',
      prompt: 'PATIENCE',
      init_room: 'giz_laronin_rm_main',
      view_width: 240,
      view_height: 160,
      time_seconds: 8,
      music_track: 'giz_laronin_snd_ambience',
      music_loops: true,
      interpolation_on: false,
      cartridge_col_primary: [200, 85, 78],
      cartridge_col_secondary: [26, 23, 33],
      cartridge_label: 'giz_laronin_spr_label',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      credits: ['Gizmo199'],
      date_added: {
        day: 26,
        month: 9,
        year: 2023,
      },
      is_enabled: true,
      supports_html: true,
      show_on_website: true,
      description: [
        "Wait for the '!' signal and press any button as quick as you can to slash your opponent!",
      ],
      how_to_play: [
        "Wait for the '!' signal and press any button as quick as you can to slash your opponent!",
      ],
    },
    authorIds: ['giz'],
  },
  {
    name: 'giz_face_off',
    config: {
      config_version: 1,
      game_name: 'Face Off',
      authors: 'Gizmo199',
      prompt: 'DE-GLOVE',
      init_room: 'giz_beast_bullet_rm_main',
      view_width: 480,
      view_height: 320,
      time_seconds: 8,
      music_track: 'giz_beast_bullet_theme_music',
      music_loops: true,
      interpolation_on: false,
      cartridge_col_primary: [71, 89, 153],
      cartridge_col_secondary: [0, 214, 179],
      cartridge_label: 'giz_beast_bullet_label',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      credits: ['Gizmo199', 'mashmerlow', 'Zandy'],
      date_added: {
        day: 26,
        month: 9,
        year: 2023,
      },
      is_enabled: true,
      supports_html: true,
      show_on_website: true,
      description: ['Obliterate Larold before he obliterates you!'],
      how_to_play: ['Obliterate Larold before he obliterates you!'],
    },
    authorIds: ['giz'],
  },
  {
    name: 'wae_HogginAHog',
    config: {
      config_version: 1,
      game_name: "Hoggin' a Hog",
      authors: 'Waesome',
      prompt: 'STEAL THE PIG!',
      init_room: 'wae_hog_room',
      view_width: 240,
      view_height: 160,
      time_seconds: 9,
      music_track: 'sng_zandy_woohoo_kong',
      music_loops: true,
      interpolation_on: false,
      cartridge_col_primary: [54, 43, 43],
      cartridge_col_secondary: [220, 55, 225],
      cartridge_label: 'wae_hog_label',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      credits: ['Waesome'],
      date_added: {
        day: 21,
        month: 6,
        year: 2024,
      },
      is_enabled: true,
      supports_html: true,
      show_on_website: true,
      description: ['Steer an unwieldy hog to freedom!'],
      how_to_play: [
        "Stabilize the hog so it faces the exit. Use left and right to affect the hog's rotation.",
      ],
    },
    authorIds: ['wae'],
  },
  {
    name: 'wae_MissileDefense',
    config: {
      config_version: 1,
      game_name: 'Missile Defense',
      authors: 'Waesome',
      prompt: 'DESTROY MISSILES!',
      init_room: 'wae_missle_room',
      view_width: 240,
      view_height: 160,
      time_seconds: 9,
      music_track: 'wae_sng_missle2',
      music_loops: true,
      interpolation_on: false,
      cartridge_col_primary: [67, 100, 204],
      cartridge_col_secondary: [153, 148, 255],
      cartridge_label: 'wae_missle_label',
      default_is_fail: false,
      supports_difficulty_scaling: true,
      credits: ['Waesome'],
      date_added: {
        day: 18,
        month: 8,
        year: 2024,
      },
      is_enabled: true,
      supports_html: true,
      show_on_website: true,
      description: ["Destroy missiles headed to Larold's Crystal!"],
      how_to_play: [
        'Angle the flak cannon with arrow keys and fire with either A or B. Destroy all missiles to win!',
      ],
    },
    authorIds: ['wae'],
  },
  {
    name: 'yosi_epic_fire_truck',
    config: {
      config_version: 1,
      game_name: 'Epic Fire Truck',
      authors: 'Yosi',
      prompt: 'MASH',
      init_room: 'yosi_EFT_rm_init',
      view_width: 240,
      view_height: 160,
      time_seconds: 10,
      music_track: 'yosi_EFT_song',
      music_loops: false,
      interpolation_on: false,
      cartridge_col_primary: [255, 50, 50],
      cartridge_col_secondary: [50, 50, 255],
      cartridge_label: 'yosi_EFT_spr_label',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      credits: ['yosi'],
      date_added: '21/07/08',
      is_enabled: true,
      supports_html: true,
      supports_pi: true,
      show_on_website: true,
    },
    authorIds: ['yosi'],
  },
  {
    name: 'grog_broombreaker',
    config: {
      config_version: 1,
      game_name: 'Broom Breaker',
      authors: {
        grog: 'Grog',
        pixpope: 'Pixelated Pope',
      },
      prompt: 'FLY',
      init_room: 'grog_bba_room',
      view_width: -1,
      view_height: -1,
      time_seconds: 8,
      music_track: 'grog_bba_bgm',
      music_loops: false,
      interpolation_on: false,
      cartridge_col_primary: [215, 123, 186],
      cartridge_col_secondary: [118, 66, 138],
      cartridge_label: 'grog_bba_cart',
      default_is_fail: false,
      supports_difficulty_scaling: true,
      credits: ['Grog', 'Pixelated Pope', 'Louie Ortiz'],
      date_added: {
        day: 17,
        month: 10,
        year: 2024,
      },
      is_enabled: true,
      supports_html: true,
      allow_subpixels: true,
      supports_pi: true,
      show_on_website: true,
      description: ['Fly between oncoming walls.'],
      how_to_play: ['Press A to ascend while avoiding oncoming walls.'],
    },
    authorIds: ['grog', 'pixpope'],
  },
  {
    name: 'rafael_fruitventure',
    config: {
      config_version: 1,
      game_name: "Rafael's FruitVenture",
      authors: {
        rafael: 'Rafael',
      },
      prompt: 'EAT AND SURVIVE',
      init_room: 'rafael_fruitventure_rm_main',
      view_width: -1,
      view_height: -1,
      time_seconds: 12,
      music_track: 'rafael_snd_fruit_mayhem',
      music_loops: false,
      interpolation_on: false,
      cartridge_col_primary: [255, 72, 0],
      cartridge_col_secondary: [101, 255, 0],
      cartridge_label: 'rafael_spr_rafael_cartridge_label',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      credits: ['Rafael'],
      date_added: {
        day: 10,
        month: 8,
        year: 2024,
      },
      is_enabled: true,
      supports_html: true,
      allow_subpixels: true,
      supports_pi: true,
      show_on_website: true,
      description: [
        'Rafael goes on an Adventure to Collect Fruits but to do so he must dodge many Foes!',
      ],
      how_to_play: [
        'Use Left and Right to Move and A to Jump and Collect all Fruits!',
      ],
    },
    authorIds: ['rafael'],
  },
  {
    name: 'mantaray_pool_dive',
    config: {
      config_version: 1,
      game_name: 'Pool Dive',
      authors: 'manta ray',
      prompt: 'DIVE!',
      init_room: 'mantaray_pool_dive_room_Game',
      view_width: 240,
      view_height: 160,
      time_seconds: 5,
      music_track: 'noone',
      music_loops: true,
      interpolation_on: false,
      cartridge_col_primary: [95, 205, 228],
      cartridge_col_secondary: [63, 63, 116],
      cartridge_label: 'mantaray_pool_dive_spr_Cartridge_Label',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      credits: ['manta ray', 'freesound.org'],
      date_added: {
        day: 31,
        month: 5,
        year: 2022,
      },
      is_enabled: true,
      supports_html: true,
      show_on_website: true,
      allow_subpixels: false,
      supports_pi: true,
      description: [
        'Attempt a dive from the heights into a small, moving pool!',
      ],
      how_to_play: [
        'Adjust the angle with left-right and the jump velocity with up-down. Dive with the primary button!',
      ],
    },
    authorIds: ['mantaray'],
  },
  {
    name: 'josh_eyes_on_eyes',
    config: {
      config_version: 1,
      game_name: 'Spookeyes',
      authors: {
        josh_: 'Josh',
        kilo: 'Kilomatter',
      },
      prompt: 'COUNT!',
      init_room: 'josh_eyes_rGame',
      view_width: 480,
      view_height: 270,
      time_seconds: 9,
      music_track: 'noone',
      music_loops: true,
      interpolation_on: false,
      cartridge_col_primary: [50, 44, 63],
      cartridge_col_secondary: [168, 84, 100],
      cartridge_label: 'josh_eyes_sLabel',
      default_is_fail: true,
      supports_difficulty_scaling: false,
      credits: ['Josh', 'Kilomatter', 'Zandy'],
      date_added: {
        day: 6,
        month: 'November',
        year: 2021,
      },
      is_enabled: true,
      supports_html: true,
      show_on_website: true,
      description: [
        'Larold is being hunted down by some very bad demons! count how many they are!',
      ],
      how_to_play: [
        'Pay close attention and count the pair of eyes you see on the screen.',
      ],
    },
    authorIds: ['josh_', 'kilo'],
  },
  {
    name: 'tfg_collision',
    config: {
      config_version: 1,
      game_name: 'Fix Collision',
      authors: 'tfg',
      prompt: 'FIND ERROR',
      init_room: 'tfg_collision_rm_game',
      view_width: 960,
      view_height: 640,
      time_seconds: 9.5,
      music_track: 'sng_ennway_bit_battle',
      music_loops: false,
      interpolation_on: false,
      cartridge_col_primary: '0x121212',
      cartridge_col_secondary: '0xffb871',
      cartridge_label: 'tfg_collision_spr_cartridge',
      default_is_fail: true,
      supports_difficulty_scaling: false,
      credits: ['tfg', 'ennway', 'zandy'],
      date_added: {
        day: 9,
        month: 7,
        year: 21,
      },
      is_enabled: true,
      supports_html: true,
      supports_pi: true,
      show_on_website: true,
    },
    authorIds: ['tfg'],
  },
  {
    name: 'tfg_swipe',
    config: {
      config_version: 1,
      game_name: 'Swipe',
      authors: 'tfg',
      prompt: 'SWIPE',
      init_room: 'tfg_swipe_rm_game',
      view_width: -1,
      view_height: -1,
      time_seconds: 5,
      music_track: 'tfg_swipe_bgm_amogus',
      music_loops: false,
      interpolation_on: true,
      cartridge_col_primary: '0x555555',
      cartridge_col_secondary: '0x959595',
      cartridge_label: 'tfg_swipe_spr_cartridge',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      credits: ['tfg', 'zandy', 'space'],
      date_added: '21/08/15',
      is_enabled: true,
      supports_html: true,
      supports_pi: true,
      show_on_website: true,
    },
    authorIds: ['tfg'],
  },
  {
    name: 'tfg_guess',
    config: {
      config_version: 1,
      game_name: 'Locate',
      authors: 'tfg',
      prompt: 'LOCATE',
      init_room: 'tfg_guess_rm_game',
      view_width: -1,
      view_height: -1,
      time_seconds: 10,
      music_track: 'sng_zandy_koto',
      music_loops: true,
      interpolation_on: false,
      cartridge_col_primary: '0x62b4de',
      cartridge_col_secondary: '0xcbf5ef',
      cartridge_label: 'tfg_guess_spr_cartridge',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      credits: ['tfg', 'space', 'zandy'],
      date_added: {
        day: 16,
        month: 1,
        year: 22,
      },
      is_enabled: true,
      supports_html: true,
      supports_pi: true,
      show_on_website: true,
    },
    authorIds: ['tfg'],
  },
  {
    name: 'space_rocket_lander',
    config: {
      config_version: 1,
      game_name: 'Rocket Lander',
      authors: 'space',
      prompt: 'LAND',
      init_room: 'space_lander_rm_lander',
      view_width: 240,
      view_height: 160,
      time_seconds: 5,
      music_track: 'space_lander_sng_zandy_aliens_edit',
      music_loops: false,
      interpolation_on: false,
      cartridge_col_primary: [0, 214, 179],
      cartridge_col_secondary: [0, 125, 150],
      cartridge_label: 'space_lander_label',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      credits: ['space'],
      date_added: '21/07/04',
      is_enabled: true,
      supports_html: true,
      supports_pi: true,
      show_on_website: true,
      description: [
        "Land a spaceship on the planet's surface. Be careful though, the ship is fragile.",
      ],
      how_to_play: [
        'Land the ship as slowly as possible to avoid crashing. Press UP to thrust',
      ],
    },
    authorIds: ['space'],
  },
  {
    name: 'space_scooter',
    config: {
      config_version: 1,
      game_name: 'Rixel Rider',
      authors: 'space',
      prompt: 'Backflip',
      init_room: 'space_scoot_rm',
      view_width: 120,
      view_height: 80,
      time_seconds: 6,
      music_track: 'space_scooter_sng_meseta_midnight_drive',
      music_loops: false,
      interpolation_on: false,
      cartridge_col_primary: [180, 92, 117],
      cartridge_col_secondary: [43, 36, 56],
      cartridge_label: 'space_scooter_spr_label',
      default_is_fail: true,
      supports_difficulty_scaling: false,
      credits: ['space', 'meseta', 'freesound.org', 'zapsplat.com'],
      date_added: '21/07/12',
      is_enabled: true,
      supports_html: true,
      supports_pi: true,
      show_on_website: true,
      description: [
        "Cruisin' through the forest at midnight with only bats for company.",
        'Can you pull off the dopest backflip the world has ever seen?',
      ],
      how_to_play: [
        'Pull off a successful backflip by holding LEFT whlile airborne. Try to land as flat as possible!',
      ],
    },
    authorIds: ['space'],
  },
  {
    name: 'space_larold_lineup',
    config: {
      config_version: 1,
      game_name: 'Larold Lineup',
      authors: 'space',
      prompt: 'Memorize',
      init_room: 'space_ll_rm_main',
      view_width: 240,
      view_height: 160,
      time_seconds: 12,
      music_track: 'sng_zandy_persnickety_noloop',
      music_loops: false,
      interpolation_on: false,
      cartridge_col_primary: [205, 114, 64],
      cartridge_col_secondary: [61, 33, 39],
      cartridge_label: 'space_ll_label',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      credits: [
        'space',
        'zandy',
        'baku',
        'sam',
        'zapsplat.com',
        'Dillon Becker',
      ],
      date_added: '21/09/22',
      is_enabled: true,
      supports_html: true,
      supports_pi: true,
      show_on_website: true,
      description: [
        'Detective Baku has been called down to the station to identify a very naughty larold.',
      ],
      how_to_play: [
        'Memorize which Larold is holding which number, then select the correct number once the guilty suspect is revealed.',
      ],
    },
    authorIds: ['space'],
  },
  {
    name: 'mimpy_dinner_date',
    config: {
      config_version: 1,
      game_name: 'Dinner Date',
      authors: 'Mimpy',
      prompt: 'REST',
      init_room: 'mimpy_duckdate_rm',
      view_width: 240,
      view_height: 160,
      time_seconds: 6,
      music_track: 'mimpy_duckdate_mus_hop',
      music_loops: false,
      interpolation_on: false,
      cartridge_col_primary: [76, 33, 33],
      cartridge_col_secondary: [102, 48, 48],
      cartridge_label: 'mimpy_duckdate_label',
      default_is_fail: false,
      supports_difficulty_scaling: false,
      credits: ['Mimpy'],
      date_added: '21/07/09',
      is_enabled: true,
      supports_html: true,
      supports_pi: true,
      show_on_website: true,
    },
    authorIds: ['mimpy'],
  },
  {
    name: 'mimpy_fire_alarm',
    config: {
      config_version: 1,
      game_name: 'Fire Alarm',
      authors: 'Mimpy',
      prompt: 'DOUSE',
      init_room: 'mimpy_firealarm_rm',
      view_width: 240,
      view_height: 160,
      time_seconds: 6,
      music_track: 'mimpy_firealarm_emergency',
      music_loops: false,
      interpolation_on: false,
      cartridge_col_primary: [200, 64, 28],
      cartridge_col_secondary: [102, 30, 32],
      cartridge_label: 'mimpy_firealarm_label',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      credits: ['Mimpy'],
      date_added: '21/07/06',
      is_enabled: true,
      supports_html: true,
      supports_pi: true,
      show_on_website: true,
    },
    authorIds: ['mimpy'],
  },
  {
    name: 'mimpy_objection',
    config: {
      config_version: 1,
      game_name: 'Objection!',
      authors: 'Mimpy',
      prompt: 'CONTRADICT',
      init_room: 'mimpy_objection_rm',
      view_width: 720,
      view_height: 480,
      time_seconds: 8,
      music_track: 'mimpy_objection_bg_jury',
      music_loops: false,
      interpolation_on: false,
      cartridge_col_primary: [94, 116, 204],
      cartridge_col_secondary: [177, 209, 215],
      cartridge_label: 'mimpy_objection_spr_label',
      default_is_fail: true,
      supports_difficulty_scaling: false,
      credits: ['Mimpy'],
      date_added: '21/08/22',
      is_enabled: true,
      supports_html: true,
      supports_pi: true,
      show_on_website: true,
    },
    authorIds: ['mimpy'],
  },
  {
    name: 'mimpy_highnote',
    config: {
      config_version: 1,
      game_name: 'High Note',
      authors: 'Mimpy',
      prompt: 'MATCH PITCH',
      init_room: 'mimpy_highnote_rm',
      view_width: 720,
      view_height: 480,
      time_seconds: 6,
      music_track: -1,
      music_loops: false,
      interpolation_on: false,
      cartridge_col_primary: [71, 28, 41],
      cartridge_col_secondary: [192, 137, 147],
      cartridge_label: 'mimpy_highnote_spr_label',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      credits: ['Mimpy'],
      date_added: '21/11/30',
      is_enabled: true,
      supports_html: true,
      supports_pi: true,
      show_on_website: true,
    },
    authorIds: ['mimpy'],
  },
  {
    name: 'anti_hats',
    config: {
      config_version: 1,
      game_name: 'Hats',
      authors: 'Antidissmist',
      prompt: 'SHADES',
      init_room: 'anti_hats_rm_start',
      view_width: 240,
      view_height: 160,
      time_seconds: 4,
      music_track: 'anti_hats_music',
      music_loops: false,
      interpolation_on: false,
      cartridge_col_primary: [29, 43, 83],
      cartridge_col_secondary: [126, 37, 83],
      cartridge_label: 'anti_hats_sp_label',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      credits: ['Antidissmist'],
      date_added: '21/07/14',
      is_enabled: true,
      supports_html: true,
      show_on_website: true,
      description: ["Hats are falling! Don't let them ruin your outfit!"],
      how_to_play: [
        'Move LEFT and RIGHT to get the shades to fall onto your head',
      ],
    },
    authorIds: ['anti'],
  },
  {
    name: 'anti_dungeon',
    config: {
      config_version: 1,
      game_name: 'Dungeon',
      authors: 'Antidissmist',
      prompt: 'FIGHT',
      init_room: 'anti_dungeon_rm_start',
      view_width: 120,
      view_height: 80,
      time_seconds: 8,
      interpolation_on: false,
      cartridge_col_primary: [26, 23, 33],
      cartridge_col_secondary: [143, 41, 85],
      cartridge_label: 'anti_dungeon_sp_label',
      default_is_fail: true,
      supports_difficulty_scaling: false,
      credits: ['Antidissmist', 'Space'],
      date_added: {
        day: 3,
        month: 'February',
        year: 2022,
      },
      is_enabled: true,
      supports_html: true,
      show_on_website: true,
      description: ['A challenger approaches!'],
      how_to_play: ['Outwit some guys'],
    },
    authorIds: ['anti'],
  },
  {
    name: 'sam_cookiedunk',
    config: {
      config_version: 1,
      game_name: 'Cookie Dunk',
      authors: 'Sam',
      prompt: 'DUNK',
      init_room: 'sam_cd_rm_game',
      view_width: 480,
      view_height: 320,
      time_seconds: 5,
      music_track: 'noone',
      music_loops: 'noone',
      interpolation_on: false,
      cartridge_col_primary: [50, 44, 63],
      cartridge_col_secondary: [168, 84, 100],
      cartridge_label: 'sam_cd_spr_label',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      credits: ['Sam'],
      date_added: {
        day: 4,
        month: 9,
        year: 2021,
      },
      is_enabled: true,
      supports_html: true,
      supports_pi: true,
      show_on_website: true,
    },
    authorIds: ['sam'],
  },
  {
    name: 'sam_catsnatch',
    config: {
      config_version: 1,
      game_name: 'Cat Snatch',
      authors: 'Sam',
      prompt: 'SNATCH!',
      init_room: 'sam_cs_rm_game',
      view_width: 480,
      view_height: 320,
      time_seconds: 5,
      music_track: 'sng_zandy_claw_machine',
      music_loops: true,
      interpolation_on: false,
      cartridge_col_primary: [10, 152, 172],
      cartridge_col_secondary: [130, 60, 61],
      cartridge_label: 'sam_cs_spr_label',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      credits: ['Sam'],
      date_added: {
        day: 6,
        month: 9,
        year: 2021,
      },
      is_enabled: true,
      supports_html: true,
      supports_pi: true,
      show_on_website: true,
    },
    authorIds: ['sam'],
  },
  {
    name: 'orphillius_lunch',
    config: {
      config_version: 1,
      game_name: 'Lunch Service',
      authors: 'Orphillius',
      prompt: 'Serve',
      init_room: 'orphillius_lunch_rm',
      view_width: 480,
      view_height: 320,
      time_seconds: 7,
      music_track: 'sng_orphillius_zany',
      music_loops: true,
      interpolation_on: true,
      cartridge_col_primary: [204, 171, 40],
      cartridge_col_secondary: [153, 9, 38],
      cartridge_label: 'orphillius_lunch_label_sp',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      credits: ['Orphillius'],
      date_added: {
        day: 18,
        month: 6,
        year: 2024,
      },
      is_enabled: true,
      supports_html: true,
      show_on_website: true,
      description: ["It's lunch time and the crowd is rolling in!"],
      how_to_play: [
        "Watch the customers' reactions and serve them the food they want.",
      ],
    },
    authorIds: ['orphillius'],
  },
  {
    name: 'orphillius_pet',
    config: {
      config_version: 1,
      game_name: 'Perfect Petsitter',
      authors: 'Orphillius',
      prompt: 'Care',
      init_room: 'orphillius_pet_rm',
      view_width: 480,
      view_height: 320,
      time_seconds: 7,
      music_track: 'sng_orphillius_zany',
      music_loops: true,
      interpolation_on: false,
      cartridge_col_primary: [0, 0, 0],
      cartridge_col_secondary: [255, 255, 255],
      cartridge_label: 'orphillius_pet_label_sp',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      credits: ['Orphillius'],
      date_added: {
        day: 4,
        month: 10,
        year: 2024,
      },
      is_enabled: true,
      supports_html: true,
      show_on_website: true,
      description: [
        'Your new pet has just arrived! Take care of it and watch it grow!',
      ],
      how_to_play: ["Satisfy your pet's requests and clean up after it!"],
    },
    authorIds: ['orphillius'],
  },
  {
    name: 'nahoo_beenade',
    config: {
      config_version: 1,
      game_name: 'Beenade',
      authors: 'Nahoo',
      prompt: 'POLLINATE',
      init_room: 'nahoo_beenade_rMain',
      view_width: 480,
      view_height: 320,
      time_seconds: 5,
      music_track: 'Nahoo_mMain',
      music_loops: true,
      interpolation_on: false,
      cartridge_col_primary: [255, 244, 101],
      cartridge_col_secondary: [241, 161, 96],
      cartridge_label: 'Nahoo_beenade_cartridge',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      credits: ['Nahoo'],
      date_added: '21/07/04',
      is_enabled: true,
      supports_html: true,
      supports_pi: true,
      show_on_website: true,
    },
    authorIds: ['nahoo'],
  },
  {
    name: 'nahoo_carttypebeat',
    config: {
      config_version: 1,
      game_name: 'Cart Type Beat',
      authors: 'Nahoo',
      prompt: 'REVOLT',
      init_room: 'nahoo_carttypebeat_rMain',
      view_width: 480,
      view_height: 320,
      time_seconds: 10,
      music_track: 'Nahoo_mMain',
      music_loops: true,
      interpolation_on: false,
      cartridge_col_primary: [75, 38, 128],
      cartridge_col_secondary: [197, 59, 157],
      cartridge_label: 'Nahoo_carttypebeat_cartridge',
      default_is_fail: true,
      supports_difficulty_scaling: false,
      credits: ['Nahoo'],
      date_added: '21/07/12',
      is_enabled: false,
      supports_html: false,
      supports_pi: false,
      show_on_website: true,
    },
    authorIds: ['nahoo'],
  },
  {
    name: 'ddgang_garyflip',
    config: {
      config_version: 1,
      game_name: 'Gary Flip',
      authors: 'Doudoudodogang',
      prompt: 'SELECT SMUG GARY',
      init_room: 'ddgang_garyflip_main',
      view_width: -1,
      view_height: -1,
      time_seconds: 6,
      music_track: 'sng_zandy_xylo',
      music_loops: true,
      interpolation_on: false,
      cartridge_col_primary: [202, 203, 208],
      cartridge_col_secondary: [0, 0, 0],
      cartridge_label: 'ddgang_garyflip_label',
      default_is_fail: true,
      supports_difficulty_scaling: true,
      credits: ['Doudoudodogang'],
      date_added: {
        day: 30,
        month: 9,
        year: 2023,
      },
      is_enabled: true,
      supports_html: true,
      show_on_website: true,
    },
    authorIds: ['ddgang'],
  },
];
export const numGames = 48;
export const numContributors = 55;
