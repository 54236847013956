






import { Component, Vue } from 'vue-property-decorator';
import gql from 'graphql-tag';

@Component({
  metaInfo: {
    titleTemplate: '%s',
  },
  apollo: {
    clientVersion: {
      query: gql`
        query App_clientVersion {
          clientVersion
        }
      `,
    },
  },
})
export default class App extends Vue {
  clientVersion = -1;
}
